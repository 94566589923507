import { Trans } from "react-i18next";
import styles from "./Follows.module.scss";
import UserItem from "app/components/Profile/user_item/UserItem";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { creatorType, ProfileListType } from "app/core/common";
import { useHook } from "app/hooks/common";
import axios from "axios";
import { useLocation } from "react-router-dom";

type FollowProps = {
  follows: string;
  following: ProfileListType[];
  followers: ProfileListType[];
  followListPage: number;
  setFollowListPage: (page: number) => void;
  followerNftData: any;
};

export default function Follows({
  follows,
  following,
  followers,
  followListPage,
  setFollowListPage,
  followerNftData,
}: FollowProps) {
  const { ref } = useInView({ threshold: 0 });
  const { userInfo } = useHook();
  const [count, setCount] = useState(10);
  const location = useLocation();
  const userId = location.pathname.split("/")[2];

  const isMyProfile = userInfo?.id === userId || userInfo?.username === userId;

  const showMore = () => {
    setCount((prev) => prev + 10);
  };

  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };

  const onScroll = () => {
    const { scrollTop, scrollHeight } = document.documentElement;
    if (window.innerHeight + scrollTop >= scrollHeight) {
      setFollowListPage(followListPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  return (
    <div className={styles.container}>
      {follows === "following" ? (
        <h1 className={styles.title}>Following</h1>
      ) : (
        <h1 className={styles.title}>
          {creatorType(userInfo?.role) === "Artist" || creatorType(userInfo?.role) === "Developer" ? "FANS" : "followers"}
        </h1>
      )}
      <div className={styles.follows_list}>
        {follows === "following" ? (
          following?.length > 0 ? (
            <>
              {following?.slice(0, count).map((user, i) => (
                <UserItem key={i} user={user} following />
              ))}
              {count < following?.length && (
                <InView as="div" onChange={handleInViewChange}>
                  <div
                    ref={ref}
                    className="view_comments"
                    onClick={showMore}
                    style={{ margin: "16px" }}
                  >
                    see more...
                  </div>
                </InView>
              )}
            </>
          ) : (
            <Trans>notFollowing</Trans>
          )
        ) : follows === "followers" ? (
          followers?.length > 0 ? (
            <>
              {followers?.slice(0, count).map((user, i) => {
                // Find the corresponding NFT data for the current user's wallet address
                const nftDataForUser = followerNftData.find(
                  (nftData) => nftData.user === user?.user?.wallet_address
                );

                return (
                  <UserItem
                    key={i}
                    user={user}
                    nftCount={nftDataForUser ? nftDataForUser.nfts : 0} // Display NFT count or 0 if not found
                  />
                );
              })}
              {count < followers?.length && (
                <InView as="div" onChange={handleInViewChange}>
                  <div
                    ref={ref}
                    className="view_comments"
                    onClick={showMore}
                    style={{ margin: "16px" }}
                  >
                    see more...
                  </div>
                </InView>
              )}
            </>
          ) : (
            <Trans>notFollowers</Trans>
          )
        ) : null}
      </div>
    </div>
  );
}
