import React, { useState, useRef, useEffect } from "react";
import styles from "./index.module.scss";
import { DirectusAudio, DirectusImage } from "app/core/common";
import Equalizer from "../equalizer/Equalizer";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Album_Benefits({
  album,
  launchpadNFT,
  index,
  albumCover,
  collection,
}: {
  albumCover?: string;
  album?: any;
  launchpadNFT?: any;
  index?: number;
  collection?: boolean;
}) {
  const banner = launchpadNFT?.info?.banner?.id;
  const [currentSong, setCurrentSong] = useState<string>("");
  const [playing, setPlaying] = useState(false);
  const equalizerRef = useRef(null);
  const timerRef = useRef<number | null>(null);

  const handlePlay = (trackId: string) => {
    if (currentSong === trackId && playing) {
      setPlaying(false);
    } else {
      setCurrentSong(trackId);
      setPlaying(true);
    }
  };

  useEffect(() => {
    if (playing && collection) {
      timerRef.current = window.setTimeout(() => {
        setPlaying(false);
        setCurrentSong("");
      }, 5000); // 5 seconds
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [playing, currentSong]);

  return (
    album && (
      <div className={styles.fan_media}>
        <div className={styles.info_header}>
          <h6>Album</h6>
        </div>
        <div className={styles.album_info}>
          <LazyLoadImage
            src={DirectusImage(banner ? banner : albumCover)}
            effect="blur"
            wrapperClassName={styles.image}
          />
          <div className={styles.album_track_genre}>
            <span>Tracks: {album?.tracks?.length}</span>
            <span>Genre: {album?.genre?.name}</span>
          </div>
        </div>
        <div className={styles.album_tracks}>
          {album?.tracks?.map((track, index) => (
            <div key={index} className={styles.album_track}>
              <div className={styles.track_id_info}>
                <div>{index + 1}.</div>
                <span
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {track?.track?.title}
                  {collection ? (
                    <>
                      <span
                        style={{
                          background: "#22213d",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontWeight: "300",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Preview
                      </span>
                    </>
                  ) : null}
                  <button
                    className={
                      playing && currentSong === track?.track?.id
                        ? styles.playing
                        : styles.playSong
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handlePlay(track?.track?.id)}
                  >
                    {playing && currentSong === track?.track?.id ? (
                      <img src="/assets/icons/media_pause.svg" />
                    ) : (
                      <img src="/assets/icons/media_play.svg" />
                    )}
                  </button>
                </span>
              </div>
              {playing && currentSong === track?.track?.id ? (
                <div className={styles.mediaPlayer}>
                  {playing && currentSong === track?.track?.id && (
                    <Equalizer
                      playing={playing}
                      equalizerRef={equalizerRef}
                      setPlaying={setPlaying}
                      song={currentSong}
                      classes={styles.equalizer}
                      barLimit={24}
                    />
                  )}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    )
  );
}
