import React, { useEffect, useState } from "react";
import styles from "./LeaderBoardList.module.scss";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { InView, useInView } from "react-intersection-observer";
import { DirectusImage } from "app/core/common";
import { truncate } from "app/helpers/helper";
import { set } from "numeral";
import Button from "app/components/button/button";
import LeaderBoard from "../../../pages/Leaderboard/LeaderBoard";
import { use } from "i18next";

type LeaderBoardListProps = {
  genres: any;
  genre: any;
  divisions: any;
  division: any;
  handleTab?: (value: string) => void;
  clearPrevContent?: () => void;
};

export default function LeaderBoardList({
  genres,
  genre,
  division,
  divisions,
  handleTab,
  clearPrevContent,
}: LeaderBoardListProps) {
  /* ======================= */
  // State management
  /* ======================= */
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [ge] = useState(1);
  const history = useHistory();
  const _genre = genres?.find((g) => g.id == genre);
  const _division = divisions?.find((d) => d.id == division);
  const [w, setW] = useState(window.innerWidth);
  const [page, setPage] = useState<number>(1);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };

  /* ======================= */
  // Clear the previous content
  /* ======================= */
  useEffect(() => {
    setLeaderboardData([]);
    setPage(1);
    setLoading(true);
    setLoadMore(true);
    //clearPrevContent();
  }, [division]);

  // Fetch the leaderboard data
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}arena/leaderboard/results/${genre}/${division}/10/${page}`
        );
        const data = await response?.data;
        console.log(data);
        setLeaderboardData((prev) =>
          page === 1 ? [...data] : [...prev, ...data]
        );
        setLoaded(true);
        setLoading(false);
        setLoadMore(data.length > 0);
      } catch (error) {
        setLoading(false);
        setLoadMore(false);
        console.error(error);
      }
    }
    fetchData();
  }, [page, division]);

  /* ======================= */
  // Show more leaderboard data
  /* ======================= */
  const showMore = () => {
    if (loadMore) {
      setPage((prev) => prev + 1);
    }
    //setPage((prev) => prev + 1);
  };

  /* ======================= */
  // Handle open profile
  /* ======================= */
  const handleOpenProfile = (item) => {
    history.push(
      `/user/${item?.artist.username ?? item?.artist.id}?tab=collections`
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setW(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.leaderboard_container}>
      <div className={styles.leaderboard_container_top}>
        <div className={styles.leaderboard_container_top_top}>
          {/* <div className={styles.leaderboard_container_top_genre}>
            <h1>Genre: {_genre?.name}</h1>
          </div> */}
          <div className={styles.leaderboard_container_top_division}>
            <h1>Division: {_division?.description}</h1>
          </div>
          <div className={styles.leaderboard_container_top_message}>
            <span>
              Click on an artist to view their profile and vote for them
            </span>
          </div>
        </div>
        <div className={styles.leaderboard_container_top_bottom}>
          <h1>RANK</h1>
          <h1>ARTIST</h1>
          <h1>N° VOTES</h1>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1>PRIZE</h1>
            <span
              style={{
                position: "absolute",
                bottom: "-12px",
                width: "100px",
                fontSize: "10px",
                fontWeight: "200",
                textAlign: "center",
                color: "gray",
              }}
            >
              (in FANS Tokens)
            </span>
          </div>
        </div>
      </div>
      <div className={styles.leaderboard_container_bottom}>
        {!loaded ? (
          <div className="loader" />
        ) : (
          <ul>
            {leaderboardData?.length > 0
              ? leaderboardData?.map((item, index) => {
                  let rank = index + 1;
                  return (
                    <li key={index}>
                      {index < 5 ? (
                        <div className={styles.star_rank}>
                          <StarIcon
                            sx={{
                              fontSize: window.innerWidth < 600 ? 40 : 50,
                              position: "absolute",
                              color: "gold",
                            }}
                          />
                          <span>{rank}</span> {/* Display rank */}
                        </div>
                      ) : (
                        <span>{rank}</span>
                      )}
                      <div
                        className={styles.user_leaderboard}
                        onClick={() => handleOpenProfile(item)}
                      >
                        <img
                          src={DirectusImage(item?.artist?.avatar?.id)}
                          alt="Loop_leaderboard_image"
                        />
                        <span>
                          {truncate(
                            item?.artist?.display_name ??
                              item?.artist?.first_name,
                            12,
                            "..."
                          )}
                        </span>
                      </div>
                      {/* Display user name */}
                      <span>{item?.votes}</span> {/* Display number of votes */}
                      <span className={styles.prize}>{item?.prize_value}</span>
                      {/* Display prize */}
                    </li>
                  );
                })
              : !loading && (
                  <div className="card" style={{ textAlign: "center" }}>
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{ marginBottom: "20px" }}
                      >
                        There are currently no votes on this leaderboard. Be the
                        first to cast your vote!
                      </h5>
                      <Button onClick={() => handleTab("list")}>
                        View Artists
                      </Button>
                    </div>
                  </div>
                )}

            {loading && <div className="loader" />}

            {!loading && loadMore && (
              <InView as="div" onChange={handleInViewChange}>
                <div ref={ref} onClick={() => showMore()}></div>
              </InView>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
