import axios from "axios";

export async function GetProfile({ id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/profile/${id}`,
    method: "get",
    data: {},
  });

  return result.data;
}

/* ================== */
// Following/Followers Count
/* ================== */
export async function fetchUserFollowCount({ userId }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/followCount`,
    method: "post",
    data: {
      userId: userId,
    },
  });

  return result.data;
}

/* ================== */
// Following List
/* ================== */
export async function fetchUserFollowingList({ userId, page, limit }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/followingList`,
    method: "post",
    data: {
      userId: userId,
      page,
      limit,
    },
  });

  return result.data;
}

/* ================== */
// Follower List
/* ================== */
export async function fetchUserFollowerList({ userId, page, limit }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/followerList`,
    method: "post",
    data: {
      userId: userId,
      page,
      limit,
    },
  });

  return result.data;
}

/* ================== */
// Fetch Followers NFTs
/* ================== */
export async function fetchUserFollowerNFT({ userId, walletAddresses }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}indexer/nfts/artist/${userId}`,
    method: "get",
    params: { walletAddrs: walletAddresses.join(",") },
  });

  return result.data;
}

export async function GetProfileGallery({ id, cookie, userInfo }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}albums/${id}`,
    method: "post",
    data: {
      cookie,
      userInfo,
    },
  });

  return result.data;
}

export async function GetProfileEvents({ id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/events/${id}`,
    method: "post",
    data: { id: id },
  });

  return result.data;
}

export async function GetProfileFeed({ id, page, userInfo, wall }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/profile/feed`,
    method: "post",
    data: {
      id: id,
      page: page,
      userInfo: userInfo,
      wall: wall,
    },
  });

  return result.data;
}

// Follow/UnFollow
export async function followAction({ cookie, userInfo, follower_id }) {
  const userData = {
    id: userInfo.id,
    profile_id: userInfo.profile_id,
  };
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/action/follow`,
    method: "post",
    data: {
      cookie: cookie,
      userInfo: userData,
      follower_id: follower_id,
    },
  });

  return result.data;
}
