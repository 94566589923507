import React from "react";
import styles from "./styles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper.scss";
import VideoCard from "./videoCard";
import SwiperCore, { Navigation, Pagination } from "swiper";

export default function VideoSlider({ videos }) {
  SwiperCore.use([Navigation, Pagination]);
  return (
    <div className={styles.wrapper}>
      <Swiper
        slidesPerView={4.2}
        spaceBetween={10}
        navigation={true}
        loop={true}
        className="video__swiper"
        breakpoints={{
          300: {
            slidesPerView: 2.2,
          },
          570: {
            slidesPerView: 3.2,
          },
          1100: {
            slidesPerView: 4.2,
          },
          1520: {
            slidesPerView: 4.2,
          },
        }}
      >
        {videos?.map((video, index) => (
          <SwiperSlide key={index}>
            <VideoCard video={video} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
