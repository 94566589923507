import Modal from "app/components/Modal";
import { useEffect, useState } from "react";
import styles from "./PaymentModal.module.scss";
import { DirectusImage } from "app/core/common";
import Button from "app/components/button/button";
import { Trans } from "react-i18next";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "app/components/CheckOutForm/CheckOutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useNotification } from "app/context/notification";
import { SyncLoader } from "react-spinners";
import { useHook } from "app/hooks/common";

const stripePromise = loadStripe(
  "pk_live_51O7PaIFxjSYqlVxInF3gyKa8d6xTIlmkzbmvVhadMIgyBreC4MQjINKrBqNPgOhZA3162VOIlexa0Q4JR00Mr74k00uR1bH9UD"
  /* "pk_test_51O7PaIFxjSYqlVxIZocBPSrr0fizbcXJHeqoMiynMpHTVZOwvlbHWN9xgn03QTCzSZQh0ZLKnGNdQ6HCM2THq8Gt00eoBdcobu" */ // Test Key
);

export const PaymentModal = ({
  showModal,
  setShowModal,
  launchpadNFT,
  index,
  collection_id,
  launchpadName,
  quantity,
  paymentSuccess,
  setPaymentSuccess,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  launchpadNFT: any;
  index: number;
  collection_id: string;
  launchpadName: string;
  quantity: number;
  paymentSuccess: boolean;
  setPaymentSuccess: (paymentSuccess: boolean) => void;
}) => {
  const [clientSecret, setClientSecret] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { notificationEvent } = useNotification();
  const appearance = {
    theme: "flat",
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const fetchData = async () => {
      try {
        setLoading((prev) => !prev);
        const paymentIntent = await axios({
          method: "post",
          url: `${process.env.REACT_APP_MIDDLEWARE}payment/intent`,
          data: {
            collection_id: collection_id,
            quantity: quantity,
            wallet_address: address,
            user: userInfo.id,
          },
        });

        if (paymentIntent) {
          setClientSecret(paymentIntent?.data?.clientSecret);
          const newUrl = new URL(window.location.href);
          newUrl.searchParams.set(
            "payment_intent_client_secret",
            paymentIntent?.data?.clientSecret
          );
          window.history.replaceState(null, "", newUrl.toString());
        }
        setLoading((prev) => !prev);
      } catch (error) {
        console.error(error);
        setLoading((prev) => !prev);
      }
    };

    fetchData();
  }, [collection_id]);

  const curDate = new Date().toISOString();
  const [type, setType] = useState<number>(1);
  const [mintCount, setMintCount] = useState(1);
  const [mintableCount, setMintableCount] = useState(0);
  const { address, userInfo } = useHook();

  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo.mintPrice?.split(" ") || ["", "USDC"];

  /* const { balance } = useJunoTokenBalance(symbol); */

  /*   const contractAddr =
      launchpadNFT?.launchpad_type[Number(index)]?.launchInfo?.NFT; */

  const mintStarted =
    curDate >=
    new Date(
      launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.startDate
    ).toISOString();

  const mintEnded =
    curDate >=
    new Date(
      launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate +
        " " +
        launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endTime
    ).toISOString();

  useEffect(() => {
    if (paymentSuccess) {
      setClientSecret("");
      notificationEvent({ subType: 3 });
    }
  }, [paymentSuccess]);

  return (
    <Modal
      isOpen={showModal}
      title={launchpadNFT?.launchpad_type[index].fan_collection.name}
      onClose={() => setShowModal(!showModal)}
      modalContentClass={styles.modalContent}
      blur={true}
    >
      <div className={styles.paymentModal}>
        <div className={styles.modalHeader}>
          <img
            src={DirectusImage(
              launchpadNFT?.launchpad_type[index]?.fan_collection.icon.id
            )}
          />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.items}>
            <hr />
            {/* Add Payment Info Below here */}
            {/* START Payment Flow */}
            <div></div>
            {/* END Payment Flow */}
            {/* Terms of Service and Data Privacy */}
            {/* {(launchpadNFT?.info?.has_tos ||
              launchpadNFT?.info?.has_policy) && (
              <div className={styles.checkbox_item}>
                <div className={styles.right}>
                  <div className={styles.checkbox}>
                    <input
                      type="checkbox"
                      id="squaredFour"
                      //checked={checked}
                      //onChange={(e) => setChecked(e.target.checked)}
                    />
                    <label htmlFor="squaredFour"></label>
                  </div>
                </div>
                <div className={styles.left}>
                  <span style={{ textAlign: "left" }}>
                    <Trans>agreeMessage</Trans>{" "}
                    {launchpadNFT?.info?.has_tos && (
                      <>
                        <b>
                          <a
                            href={launchpadNFT?.info?.tos_link}
                            target="_blank"
                          >
                            <Trans>termsOfServiceSm</Trans>
                          </a>
                        </b>
                      </>
                    )}{" "}
                    {launchpadNFT?.info?.has_policy && (
                      <>
                        {launchpadNFT?.info?.has_tos && (
                          <>
                            <>and</>{" "}
                          </>
                        )}
                        <b>
                          <a
                            href={launchpadNFT?.info?.policy_link}
                            target="_blank"
                          >
                            <Trans>privacyPolicySm</Trans>
                          </a>
                        </b>
                      </>
                    )}
                  </span>
                </div>
              </div>
            )} */}
            {loading ? (
              <SyncLoader color="#9b83f9" />
            ) : (
              clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm
                    price={price}
                    quantity={quantity}
                    setPaymentSuccess={setPaymentSuccess}
                  />
                </Elements>
              )
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
