import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useHook } from "app/hooks/common";
import { DirectusImage } from "app/core/common";
import MusicTable from "./musicTable";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import MusicList from "./musicList";
import { useMediaPlayer } from "app/context/mediaPlayerContext";
import axios from "axios";
import Loader from "app/components/Loader";

interface Track {
  track: {
    id: string;
    title: string;
    artist: {
      username: string;
    };
    artwork: string;
    duration: number;
  };
}

interface Album {
  id: string;
  name: string;
  tracks: number;
  artist: string;
}

// Define the type for the index
type Index = number;

export default function MusicLibrary() {
  const { cookie, userInfo } = useHook();
  const [loading, setLoading] = useState<boolean>(false);
  const [playingIndex, setPlayingIndex] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tracks, setTracks] = useState([]);
  const [albums, setAlbums] = useState([]);

  const {
    toggleMediaPlayerModal,
    tooglePlayPause,
    handleTrack,
    showPlayerModal,
    actualTrack,
    play,
    handleTracks,
  } = useMediaPlayer();

  // presionando play cambiar toglleMediaPlayer a true,

  useEffect(() => {
    const fetchMusic = async () => {
      setLoading((prev) => !prev);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}music/library`,
          {
            headers: {
              "Content-Type": "application/json",
              user_cookie: cookie,
            },
          }
        );

        if (response) {
          console.log(response);
          setTracks(response.data);
        }
        setLoading((prev) => !prev);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMusic();
  }, [cookie, userInfo]);

  const handlePlayPause = (track: Track, index: Index) => {
    if (
      actualTrack?.track?.id === track?.track?.id &&
      showPlayerModal &&
      play
    ) {
      tooglePlayPause(false); // Pause the current song
      setPlayingIndex(null);
    } else {
      if (tracks?.length > 1) {
        handleTracks(tracks, index); // Pass the tracks array and the current index
        toggleMediaPlayerModal(true); // Open the media player
        tooglePlayPause(true); // Play the new song
        setPlayingIndex(index);
      } else {
        handleTrack(track); // Set the current track
        toggleMediaPlayerModal(true); // Open the media player
        tooglePlayPause(true); // Play the new song
        setPlayingIndex(index);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.music_library_container}>
      <div className={styles.music_library_container__header}>
        <div className={styles.music_library_container__header_infos}>
          <div className={styles.music_library_container__header_infos_details}>
            <div
              className={
                styles.music_library_container__header_infos_details_text
              }
            >
              <h1>Music Library</h1>
              <p>{tracks?.length} Tracks</p>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(23, 25, 27, 0.48)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : windowWidth > 767 ? (
        <div className={styles.musicTableContainer}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Album</th>
                <th>Track</th>
                <th>Artist</th>
                <th>Duration</th>
                <th style={{ color: "transparent" }}>Playing</th>
              </tr>
            </thead>
            <tbody>
              {tracks?.map((track, index) => (
                <MusicTable
                  key={index}
                  track={track}
                  index={index}
                  isPlaying={playingIndex === index}
                  handlePlayPause={() => handlePlayPause(track, index)}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <hr />
          {tracks?.map((track, index) => (
            <MusicList
              key={index}
              track={track}
              handlePlaySong={() => handlePlayPause(track, index)}
            />
          ))}
        </>
      )}
    </div>
  );
}
