import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./feed.module.scss";
import { Link } from "react-router-dom";
import {
  DefaultAvatar,
  DirectusAudio,
  DirectusImage,
  isCreator,
} from "app/core/common";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import VerifiedIcon from "@mui/icons-material/Verified";
import blue from "@mui/material/colors/blue";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TimeAgo from "app/helpers/timeago";
import { Trans } from "react-i18next";
import { formatNumber, truncate } from "app/helpers/helper";
import { GatedContentCheck } from "./gatedContent";
import ReactPlayer from "react-player";
import comment_icon from "../../../assets/icons/comment.svg";
import like_icon from "../../../assets/icons/like.svg";
import liked_icon from "../../../assets/icons/liked.svg";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useClickOutside from "app/helpers/clickOutside";
import Button from "../button/button";
import axios from "axios";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TextArea from "../createContent/TextArea";
import { useMediaPlayer } from "app/context/mediaPlayerContext";

export default function PostItem({
  item,
  feed,
  setFeed,
  index,
  setItemIndex,
  setShowDetailModal,
  handleSingleTap,
  userInfo,
  setConfirmDelete,
  cookie,
  handleConfirmDelete,
  editedPostId,
  setEditedPostId,
  playSong,
  setPlaySong,
}: {
  index: number;
  setItemIndex: Dispatch<SetStateAction<number>>;
  feed: any;
  setFeed: Dispatch<SetStateAction<any>>;
  cookie: string;
  setConfirmDelete?: Dispatch<SetStateAction<boolean>>;
  handleSingleTap?: any;
  item?: any;
  setShowDetailModal: Dispatch<SetStateAction<boolean>>;
  userInfo?: any;
  handleConfirmDelete: (value) => void;
  editedPostId: string;
  setEditedPostId: Dispatch<SetStateAction<string>>;
  playSong?: string;
  setPlaySong?: Dispatch<SetStateAction<string>>;
}) {
  const { tooglePlayPause, play } = useMediaPlayer();
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [postMenu, setPostMenu] = useState(false);
  const [editPost, setEditPost] = useState(false);
  const [text, setText] = useState(null);
  const [textEdited, setTextEdited] = useState("");
  const [loading, setloading] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const modal = useRef(null);
  const textareaRef = useRef(null);
  const song = item?.song?.id;
  const postId = item.id;
  const audioRef = useRef(null);

  /**********************/
  // funtion control if its the same song play it, if not pause it
  /**********************/
  const handlePlayer = () => {
    tooglePlayPause(false);
    setPlaySong(song);
    setCurrentSong(song);
    if (playSong === currentSong) {
      audioRef?.current?.play();
    } else {
      audioRef?.current?.pause();
    }
  };
  /**********************/
  //  funtion control if its the same song play it, if not pause it
  /**********************/

  useEffect(() => {
    if (play) {
      audioRef.current?.pause();
    }
  }, [play]);

  useEffect(() => {
    if (playSong === song) {
      audioRef?.current?.play();
    } else {
      audioRef?.current?.pause();
    }
  }, [playSong]);
  /**********************/
  // funtion to close a modal when click outside
  /**********************/
  useClickOutside(modal, () => setPostMenu(false));

  /**********************/
  // when edit post its true
  /**********************/
  // useEffect(() => {
  //   if (editPost) {
  //     // Focus on the textarea
  //     textareaRef?.current.focus();
  //     // Move the cursor to the end of the text
  //     textareaRef?.current.setSelectionRange(
  //       textEdited?.length,
  //       textEdited?.length
  //     );
  //   }
  // }, [editPost]);

  /**********************/
  // setting the id if the post, and controlling when to open or close it.
  /**********************/

  const handleCloseModal = () => {
    setEditedPostId(item?.id);
    setCurrentPost(item?.id);
    if (postMenu === true) {
      setPostMenu(false);
    } else {
      setPostMenu(true);
    }
  };
  /**********************/
  // if the post its the same, open edit option, and closing menu modal
  /**********************/
  const handleEdit = () => {
    if (editedPostId === currentPost) {
      setEditPost(true);
      setPostMenu(false);
    } else {
      setEditPost(false);
    }
  };
  /**********************/
  // if the post that its been edited, its not the one that its open now, close it.
  /**********************/
  useEffect(() => {
    if (editedPostId === item?.id) {
      return;
    } else {
      setEditPost(false);
    }
  }, [editedPostId, feed]);

  /*set editedText with the content of the post */

  useEffect(() => {
    setTextEdited(item?.content);
  }, [item, postMenu]);

  /*detect the changes made at the textfield, store the changes in a different variable */

  const handleChange = (e) => {
    const newText = e;
    setTextEdited(newText);
  };

  /*when the change its real, set the real text to the stored changed value */

  const handleSaveChange = async () => {
    if (item.content !== textEdited) {
      try {
        setloading((prev) => !prev);
        await axios({
          method: "patch",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/post/${postId}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            cookie,
            content: textEdited,
          },
        });
        setText(textEdited);
        setloading((prev) => !prev);
        setEditPost((prev) => !prev);

        const updatedFeed = feed.map((feedItem) => {
          if (feedItem.id === postId) {
            return { ...feedItem, content: textEdited };
          }
          return feedItem;
        });

        setFeed(updatedFeed);
      } catch (error) {
        setloading((prev) => !prev);
        setEditPost((prev) => !prev);
        console.error(error);
      }
    } else {
      setEditPost((prev) => !prev);
    }
  };

  return (
    <div className={styles.feed_item}>
      <div className={styles.content}>
        <div className={styles.creator}>
          <Link to={`/user/${item.artist.username ?? item.artist.id}/`}>
            <img
              src={
                item?.artist?.avatar
                  ? DirectusImage(item?.artist?.avatar)
                  : DirectusImage(DefaultAvatar)
              }
            />
          </Link>
        </div>

        <div>
          <b>
            {item.wall_user ? (
              Object.keys(item?.wall_user).length === 0 ||
              item?.wall_user.first_name === item.artist.first_name ? (
                <Link to={`/user/${item.artist.username ?? item.artist.id}/`}>
                  {item.artist.display_name ?? item.artist.first_name}:
                </Link>
              ) : (
                <Link to={""}>
                  <span>
                    <Link
                      style={{ display: "flex", alignItems: "center" }}
                      to={`/user/${item.artist.username ?? item.artist.id}/`}
                    >
                      {item.artist.display_name ?? item.artist.first_name}{" "}
                      {isCreator(item.artist?.role) ? (
                        <Tooltip title={"Verified Artist"}>
                          <VerifiedIcon
                            fontSize="small"
                            sx={{ color: blue[400] }}
                          />
                        </Tooltip>
                      ) : null}
                    </Link>
                  </span>
                  <div className={styles.arrow}>
                    <PlayArrowIcon fontSize="small" />
                    <div className={styles.arrow_name}>
                      <Link
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                        to={`/user/${
                          item.wall_user.username ?? item.wall_user.id
                        }/`}
                      >
                        <span>
                          {item?.wall_user?.display_name ??
                            item?.wall_user?.first_name}
                        </span>
                      </Link>
                    </div>
                  </div>
                </Link>
              )
            ) : (
              <>
                <Link
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  to={`/user/${item.artist.username ?? item.artist.id}/`}
                >
                  {item.artist.display_name ?? item.artist.first_name}
                  {isCreator(item.artist?.role) ? (
                    <Tooltip title={"Verified Artist"}>
                      <VerifiedIcon
                        fontSize="small"
                        sx={{ color: blue[400] }}
                      />
                    </Tooltip>
                  ) : null}
                </Link>
                {/* Show something when item.wall_user is true */}
              </>
            )}
          </b>
          <p>{TimeAgo(item?.date_created)}</p>
        </div>
        {item.visibility == "Private" && (
          <div className={styles.visibility}>
            <Trans>exclusive</Trans>
          </div>
        )}
        {item?.artist?.id === userInfo?.id ? (
          <div className={styles.options} ref={modal}>
            <div
              className={styles.post_options}
              onClick={() => handleCloseModal()}
            >
              <MoreHorizIcon />
            </div>
            {postMenu ? (
              <div className={styles.post_option_modal}>
                <div
                  className={styles.option_edit}
                  onClick={() => handleEdit()}
                >
                  <EditIcon fontSize="small" />
                  <span>Edit</span>
                </div>
                <div
                  className={styles.option_delete}
                  onClick={() => {
                    handleConfirmDelete(postId);
                    setPostMenu(false);
                  }}
                >
                  <DeleteForeverIcon fontSize="small" />
                  <span>Delete</span>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className={styles.post_content}>
        {editPost ? (
          <div className={styles.contentArea}>
            <TextArea
              textEdited={textEdited}
              edit
              setTextEdited={setTextEdited}
            />
          </div>
        ) : (
          <p>
            <span
            /* onClick={() => {
            setItemIndex(index);
            setShowDetailModal(true);
          }} */
            >
              {item.visibility === "Private" ? (
                item.access ? (
                  truncate(
                    text
                      ? text.replace(/<[^>]+>/g, "")
                      : item?.content.replace(/<[^>]+>/g, ""),
                    150,
                    "...more",
                    () => {
                      setItemIndex(index);
                      setShowDetailModal(true);
                    }
                  )
                ) : (
                  <GatedContentCheck
                    creator={item?.artist}
                    content={truncate(
                      text
                        ? text.replace(/<[^>]+>/g, "")
                        : item?.content.replace(/<[^>]+>/g, ""),
                      90,
                      ""
                    )}
                  />
                )
              ) : (
                truncate(
                  text
                    ? text.replace(/<[^>]+>/g, "")
                    : item?.content.replace(/<[^>]+>/g, ""),
                  150,
                  "...more",
                  () => {
                    setItemIndex(index);
                    setShowDetailModal(true);
                  }
                )
              )}
            </span>
          </p>
        )}
        {item?.song?.id?.length > 0 ? (
          <audio
            ref={audioRef}
            src={DirectusAudio(item?.song?.id)}
            controls
            loop
            autoPlay={false}
            className={styles.audio_player}
            controlsList="nodownload noplaybackrate"
            onPlay={() => handlePlayer()}
          />
        ) : null}

        <div
          className={`imageContainer-${item.id} ${styles.media}`}
          data-id={item.id}
        >
          <>
            {item.image.id ? (
              (item.visibility === "Private" && item.access) ||
              item.visibility === "Public" ? (
                item.type == "video" ? (
                  <ReactPlayer
                    url={DirectusImage(item.image.id)}
                    controls={true}
                    playsinline
                    muted
                    onProgress={(state) => {
                      setCurrentTime(state.playedSeconds);
                    }}
                    pip={false}
                    width="100%"
                    height="560px"
                    style={{ maxHeight: "650px" }}
                  />
                ) : (
                  <>
                    <img src={DirectusImage(item.image.id)} />
                    <div
                      className={styles.overlay}
                      onClick={() => {
                        setItemIndex(index);
                        setShowDetailModal(true);
                      }}
                    />
                  </>
                )
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
          <div className={`heart-icon ${styles.heart}`} data-id={item.id} />
        </div>
      </div>
      {(item.visibility === "Private" && item.access) ||
      item.visibility === "Public" ? (
        <>
          <div className={styles.actions}>
            {/* Likes */}
            <div className={styles.post_actions}>
              <div
                className={`${styles.action} ${item.liked ? styles.liked : ""}`}
              >
                <img
                  alt={`like-${item.id}`}
                  src={item.liked ? liked_icon : like_icon}
                  onClick={() => handleSingleTap(item.id)}
                />{" "}
                {/* <p>Liked Post</p>  */}
                <span>{formatNumber(item.likes)}</span>
              </div>
              {/* Comments */}
              <div
                className={styles.action}
                onClick={() => {
                  setItemIndex(index);
                  setShowDetailModal(true);
                }}
              >
                <img alt={`${item.id}`} src={comment_icon} />{" "}
                {/* <p>Comments</p> */}{" "}
                <span>{formatNumber(item.comments)}</span>
              </div>
            </div>

            {editPost ? (
              <div className={styles.edit_post_options}>
                {/*cancel editing */}
                <div
                  className={styles.edit_post_option}
                  onClick={() => {
                    setEditPost((prev) => !prev);
                    setTextEdited(text);
                  }}
                >
                  Cancel
                </div>
                {/*Confimr editing */}
                <Button
                  children={"Save"}
                  className={styles.edit_post_option_save}
                  size="sm"
                  onClick={() => {
                    handleSaveChange();
                  }}
                  loading={loading}
                />
              </div>
            ) : null}
          </div>

          {/* <div
          onClick={() => {
            setItemIndex(index);
            setShowDetailModal(true);
          }}
          className={styles.comments}
        >
          View All Comments
        </div> */}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
