import Creators from "app/components/leaderboard/Creators";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useHook } from "app/hooks/common";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { InView, useInView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./CollaborationRequest.module.scss";
import Modal from "app/components/Modal";
import Button from "app/components/button/button";

export default function CollaborationRequest(
 {
  creatorId,
  showModal,
  setShowModal,
 } : {
  creatorId: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
 }
) {
  const { userInfo, cookie } = useHook();
  const [loading, setLoading] = useState(false);
  const [collab, setCollab] = useState(false);
  const textAreaRef = useRef(null);
  const [messageTitle, setMessageTitle] = useState(null);
  const [messageText, setMessageText] = useState(null);
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    // set aboutText with the text field
    const newText = e.target.value;

    if (e.target.name === "title") {
      setMessageTitle(newText);
    } else {
      setMessageText(newText);
    }

    // Automatically adjust height of the text field as the user types
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleSendMessage = async () => {
    console.log({messageText, messageTitle})
    if (messageText) {
      try {
        setLoading(true);

        try {
          await axios.post(
            `${process.env.REACT_APP_MIDDLEWARE}arena/inbox/createInbox`,
            {
              cookie,
              receiver_id: creatorId,
              title: messageTitle ?? "Hey, I'll like to collaborate with you!",
              message: messageText,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setSent(true);
        } catch (error) {
          setMessageText("");
          setMessageTitle("");
          setLoading(false);
          setCollab(false);
          console.error(error);
        }
        setTimeout(() => {
          setLoading(false);
          setCollab(false);
          setSent(false);
          setMessageText("");
          setMessageTitle("");
        }, 2000);
      } catch (error) {
        setMessageText("");
        setMessageTitle("");
        setLoading(false);
        setCollab(false);
        console.error(error);
      }
    } else {
      return;
    }
  };

  return (
    <div className={styles.collab}>

      {loading && <div className="loader" />}
      {/* {voting ? <VoteModal creator={creator} /> : null} */}
      <Modal
        onClose={() => setShowModal(false)}
        isOpen={showModal}
        title={"Message Request"}
        customSubClass="defaultModal"
      >
        <div className="defaultModalContent">
          {sent ? (
            <div className={styles.alert_message} role="alert">
              Message Sent!
              <CheckCircleOutlineIcon fontSize="large" color="success" />
            </div>
          ) : (
            <>
              <input
                name="title"
                type="text"
                className={styles.input_title_message}
                placeholder="Title"
                defaultValue={`Hey, I'll like to collaborate with you!`}
                onChange={handleChange}
              />
              <textarea
                name="message"
                ref={textAreaRef}
                className={styles.text_area_modal}
                value={messageText}
                onChange={handleChange}
                maxLength={500}
                placeholder="Message..."
              />
              <div className="defaultModalButtons">
                <Button
                  size="sm"
                  className="btn_none"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>

                <Button
                  size="sm"
                  className="btn_danger"
                  onClick={() => handleSendMessage()}
                  loading={loading}
                >
                  Send
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
