import Countdown, { zeroPad } from "react-countdown";
import moment from "moment-timezone";
import { LaunchPadNFT } from "app/core/common";

const CountdownComp = ({
  launchpadNFT,
  index,
}: {
  launchpadNFT: LaunchPadNFT;
  index: number;
}) => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <>
        <p>
          {days}d<span> : </span>
          {zeroPad(hours)}h<span> : </span>
          {zeroPad(minutes)}m<span> : </span>
          {zeroPad(seconds)}s
        </p>
      </>
    );
  };

  const currentTime = moment.utc().valueOf();
  const genTime = (time: string) =>
    moment(time).add(moment(time).utcOffset(), "minutes").valueOf();

  const MintStartTime = genTime(
    moment
      .utc()
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.startDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.startTime
        }`
      )
  );
  const EndTime = genTime(
    moment
      .utc()
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.endDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.endTime
        }`
      )
  );

  const beforeStartTime = currentTime < MintStartTime;
  return (
    <>
      <div className="header">
        <div className="time">
          <small>{beforeStartTime ? <>Start Date</> : <>End Date</>}</small>
          {/* {(beforeWhitelistMint || isWhitelistMint || isPublicMint) && ( */}
          <Countdown
            date={
              beforeStartTime
                ? MintStartTime
                : EndTime
            }
            renderer={renderer}
          />
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default CountdownComp;
