import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Profile.module.scss";
import VerifiedIcon from "@mui/icons-material/Verified";
import Button from "app/components/button/button";
import { useHistory, useLocation } from "react-router-dom";
import * as api from "app/api/profile.api";
import ProfileNfts from "./Nfts";
import {
  DefaultAvatar,
  DirectusImage,
  FollowCountType,
  ProfileData,
  ProfileListType,
  creatorType,
  isCreator,
} from "app/core/common";
import ProfileCollections from "./Collections";
import CreatorCourses from "./Courses";
import ProfileFeed from "./Feed";
import ProfileGallery from "./Gallery";
import { useFollow } from "app/state/FollowState";
import { useHook } from "app/hooks/common";
import { Trans } from "react-i18next";
import { useWallet } from "cloud-social-wallet";
import { useLogin } from "app/context/loginProvider";
import { ProfileTabs } from "./ProfileTabs";
import ProfileEvents from "./Events/Events";
import ModalProfile from "app/components/Profile/modal_profile/ModalProfile";
import axios from "axios";
import AboutMe from "./AboutMe/AboutMe";
import Follows from "./follows/Follows";
import { blue } from "@mui/material/colors";
import { getParam, setParam } from "app/helpers/helper";
import UnderProfileTab from "app/components/Profile/under_profile_tab/UnderProfileTab";
import { CreateContent } from "app/components/createContent/createContent";
import ShareComponent from "app/components/share_component/ShareComponent";
import ShareIcon from "@mui/icons-material/Share";
import ReplyIcon from "@mui/icons-material/Reply";
import CollaborationRequest from "app/components/modals/CollaborationRequest/CollaborationRequest";
import MediaPlayer from "app/components/MediaPlayer/MediaPlayer";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import AdvancedView from "./AdvancedView";
import VotingView from "./VotingView";
import VoteModal from "app/components/leaderboard/vote_modal/voteModal";

const colabIcon = "/assets/icons/collab.svg";
const colabIconWhite = "/assets/icons/collab_white.svg";

export default function Profile({ containerRef }) {
  const location = useLocation();
  const history = useHistory();
  const [imageProfile, setImageProfile] = useState(null);
  const [imageCover, setImageCover] = useState(null);
  const [profileData, setProfileData] = useState<ProfileData>(null);
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const [songFile, setSongFile] = useState(null);
  const userId = location.pathname.split("/")[2];
  const tabName = getParam("tab");
  const editParam = getParam("edit");
  const [underTabName, setUnderTabName] = useState(() => getParam("postTab"));
  const [follows, setFollows] = useState(tabName || "followers");
  const [share, setShare] = useState(false);
  const [collaborationRequestModal, setCollaborationRequestModal] =
    useState(false);
  const [collabHover, setCollabHover] = useState(false);
  const [showSong, setShowSong] = useState(null);

  const { logout } = useWallet();
  const [followCount, setFollowCount] = useState<FollowCountType>(null);
  const [followingList, setFollowingList] = useState<ProfileListType[]>([]);
  const [followerList, setFollowerList] = useState<ProfileListType[]>([]);
  const [followListPage, setFollowListPage] = useState(1);
  const [followerNftData, setFollowerNftData] = useState([]);

  // Handle Voting
  const [voting, setVoting] = useState(false);
  const [collection, setCollection] = useState<any>();

  const { userInfo, cookie, updateUserInfo } = useHook();

  const isUserProfile =
    userInfo?.id === userId || userInfo?.username === userId;

  const [profileModal, setProfileModal] = useState(false);
  // Latest NFTs
  const [latestNfts, setLatestNfts] = useState([]);
  // User Genres
  const [genres, setGenres] = useState([]);
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [max, setMax] = useState(
    profileData?.description ? 110 - profileData?.description.length : 110
  );
  const [details, setDetails] = useState(null);
  const pRef = useRef(null);
  const cRef = useRef(null);

  const profileImage = profileData?.avatar?.id;

  const [feed, setFeed] = useState([]);
  const [feedMeta, setFeedMeta] = useState<any>();
  const [page, setPage] = useState(1);
  const { isFollowing, isFollowingLoading, toggleFollow } = useFollow({
    follower_id: profileData?.id,
  });
  const [showCTA, setShowCTA] = useState(false);
  const prevUsernameRef = useRef<string | null>(null);
  const access_courses = profileData?.access_courses ? true : false;
  const { toggleLoginModal, toogleRegisterModal } = useLogin();

  /**********************/
  // Check if Following
  /**********************/
  /*  useEffect(() => {
    const following = followingList.find(
      (obj) => obj.follower_id.id === username
    );
    setIsFollowing(following);
    setIsFollowingLoading(false);
  }, [followingList]); */

  /**********************/
  // Clear Feed/Profile data on profile change
  /**********************/

  useEffect(() => {
    if (
      prevUsernameRef.current !== null &&
      prevUsernameRef.current !== userId
    ) {
      setFeed([]);
      setFeedMeta({});
      setPage(1);
      setProfileData(null);
      setGenres([]);
    }
    prevUsernameRef.current = userId;
  }, [userId]);

  /**********************/
  // Get Profile/Owner data
  /**********************/
  useEffect(() => {
    const fetchProfileData = async () => {
      if (!fetchingProfile) {
        setFetchingProfile(true);
        await api.GetProfile({ id: userId }).then((user) => {
          setProfileData(user[0]);
          setDetails(user[0]);
          setLatestNfts(user.fans_nfts);
        });

        await api.fetchUserFollowCount({ userId: userId }).then((data) => {
          setFollowCount(data);
        });

        setFetchingProfile(false);
      } else {
        setProfileData((prev) => prev);
        setDetails((prev) => prev);
        setLatestNfts((prev) => prev);
        setFetchingProfile(false);
      }
    };

    fetchProfileData();
  }, [userId]);

  /**********************/
  // show CTA finish profile
  /**********************/
  useEffect(() => {
    if (!profileData?.username || !profileData?.avatar) {
      setShowCTA(true);
    } else {
      setShowCTA(false);
    }
  }, [profileData]);

  /**********************/
  // Loading Following/Followers
  /**********************/
  useEffect(() => {
    if (tabName === "following") {
      api
        .fetchUserFollowingList({
          userId: userId,
          page: followListPage,
          limit: 15,
        })
        .then((data) => {
          setFollowingList((prev) => [...prev, ...data]);
        });
    } else if (tabName === "followers") {
      api
        .fetchUserFollowerList({
          userId: userId,
          page: followListPage,
          limit: 15,
        })
        .then((data) => {
          setFollowerList((prev) => [...prev, ...data]);

          const walletAddresses = data.map(
            (user) => user?.user?.wallet_address
          );
          api
            .fetchUserFollowerNFT({
              userId: userId,
              walletAddresses: walletAddresses,
            })
            .then((data) => {
              setFollowerNftData((prev) => [...prev, ...data]);
            });
        });
    }
  }, [tabName, followListPage, userId]);

  /**********************/
  // Check if page has tab route if not redirect to collections || nfts
  /**********************/
  useEffect(() => {
    if (!tabName && profileData) {
      if (isCreator(profileData?.role)) {
        setParam("tab", "posts");
        //history.push(`/user/${userId}/collections`);
      } else {
        setParam("tab", "posts");
        //history.push(`/user/${userId}/posts`);
      }
    }
  }, [tabName, profileData]);

  useEffect(() => {
    if (tabName === "posts" && (!underTabName || underTabName === "")) {
      setParam("postTab", "creator_posts");
      setUnderTabName("creator_posts");
    }
  }, [tabName, underTabName]);

  /**********************/
  // Get Profile Feed
  /**********************/
  /*   useEffect(() => {
    api.GetProfileFeed({ id: userId, page: page }).then((data) => {
      setFeed(data.feed);
      setFeedMeta(data.meta);
    });
  }, []); */

  /**********************/
  // Handle Tab Change
  /**********************/
  const handleTab = useCallback((tabName: any) => {
    setParam("tab", tabName);
    setFollowListPage(1);
    setFollowingList([]);
    setFollowerList([]);
  }, []);

  const handleUnderTab = useCallback((underTabName: any) => {
    //history.push(`/user/${userId}/${tabName}`);
    setParam("postTab", underTabName);
    setUnderTabName(underTabName);
    setFollowListPage(1);
    setFollowingList([]);
    setFollowerList([]);
  }, []);

  const [infos, setInfos] = useState({
    username: "",
    display_name: "",
    description: "",
    about: "",
    location: "",
    role: "",
  });

  const [socials, setSocials] = useState({
    spotify: "",
    soundcloud: "",
    apple: "",
    instagram: "",
    youtube: "",
    tiktok: "",
    facebook: "",
    twitter: "",
    custom_link: "",
  });

  // Update state when profileData changes
  useEffect(() => {
    // Check if profileData is available before updating state
    if (profileData) {
      setInfos({
        username: profileData?.username || "",
        display_name: profileData?.display_name || "",
        description: profileData?.description || "",
        about: profileData?.about || "",
        location: profileData?.location || "",
        role: profileData?.role || "",
      });
      setSocials({
        spotify: profileData?.socials?.spotify || "",
        soundcloud: profileData?.socials?.soundcloud || "",
        apple: profileData?.socials?.apple || "",
        instagram: profileData?.socials?.instagram || "",
        youtube: profileData?.socials?.youtube || "",
        tiktok: profileData?.socials?.tiktok || "",
        facebook: profileData?.socials?.facebook || "",
        twitter: profileData?.socials?.twitter || "",
        custom_link: profileData?.socials?.custom_link || "",
      });
      setShowSong(profileData?.show_featured_song == true ? "yes" : "no");
    }
  }, [profileData]);

  const updateAbout = async () => {
    const formData = new FormData();
    formData.append("profile_avatar", imageProfile as Blob);
    formData.append("profile_background", imageCover as Blob);
    // Append additional fields to formData
    formData.append("user_id", userInfo?.id);
    formData.append("profile_id", userInfo?.profile_id);
    formData.append("cookie", cookie);
    formData.append(
      "profile_displayName",
      infos?.display_name ? infos?.display_name : userInfo?.first_name
    );
    formData.append("profile_featured_song", songFile ? songFile : "");
    formData.append(
      "profile_show_featured_song",
      showSong === "no" ? "false" : "true"
    );
    formData.append("profile_location", infos?.location ? infos?.location : "");

    formData.append("profile_username", infos?.username ? infos?.username : "");

    formData.append(
      "profile_description",
      infos?.description ? infos?.description : userInfo?.description
    );
    formData.append(
      "profile_about",
      infos?.about ? infos?.about : userInfo?.about
    );

    formData.append("profile_type", infos?.role);

    formData.append(
      "profile_socials",
      JSON.stringify([
        { name: "spotify", value: socials?.spotify || "" },
        { name: "soundcloud", value: socials?.soundcloud || "" },
        { name: "apple", value: socials?.apple || "" },
        { name: "instagram", value: socials?.instagram || "" },
        { name: "youtube", value: socials?.youtube || "" },
        { name: "tiktok", value: socials?.tiktok || "" },
        { name: "facebook", value: socials?.facebook || "" },
        { name: "twitter", value: socials?.twitter || "" },
        { name: "custom_link", value: socials?.custom_link || "" },
      ])
    );

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}user/action/update_profile`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      if (imageProfile) {
        const imageUrl = URL.createObjectURL(imageProfile);
        pRef.current.src = imageUrl;
      }

      if (imageCover) {
        const imageUrlCover = URL.createObjectURL(imageCover);

        cRef.current.style.backgroundImage = `url(${imageUrlCover})`;
      }
      setProfileModal(false);
      setDetails(infos);
      updateUserInfo();
      history.push(`/user/${userInfo.username ?? userInfo?.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (selectedOption) => {
    const [name] = Object.keys(selectedOption); // Extracts the first (and in this case, the only) key
    const value = selectedOption[name]; // Retrieves the corresponding value

    setInfos({ ...infos, [name]: value });

    if (name === "description") {
      setMax(110 - value.length);
    }
  };

  const handleSocialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const lowercaseName = name.toLowerCase(); // Convert name to lowercase
    setSocials({ ...socials, [lowercaseName]: value });
  };

  useEffect(() => {
    if (profileModal || share) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = "auto"; // Allow scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on component unmount
    };
  }, [profileModal, share]);

  useEffect(() => {
    if (editParam && cookie) {
      const handleOpenEdit = () => {
        setTimeout(() => {
          setProfileModal((prev) => !prev);
        }, 4000);
      };
      handleOpenEdit();
    }
  }, [editParam]);

  return (
    <div className={styles.profile}>
      {profileData ? (
        <>
          {(tabName != "nfts" || (tabName === "nfts" && !isUserProfile)) && (
            <>
              <div
                className={styles.header}
                style={{
                  backgroundImage: `url(${DirectusImage(
                    profileData?.background?.id
                  )})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                ref={cRef}
              />

              <div className={styles.profile_header}>
                <div className={styles.avatar}>
                  <img
                    src={
                      profileImage
                        ? DirectusImage(profileImage)
                        : DirectusImage(DefaultAvatar)
                    }
                    ref={pRef}
                    alt="profile_loop_image"
                  />
                </div>
                <div className={styles.profile_info_edit}>
                  <div className={styles.follow_data}>
                    <div
                      className={styles.following}
                      onClick={() => {
                        handleTab("following");
                        setFollows("following");
                      }}
                    >
                      <div className={styles.following_number}>
                        {followCount?.following_count ?? 0}
                      </div>
                      <span>Following</span>
                    </div>
                    <div
                      className={styles.followers}
                      onClick={() => {
                        handleTab("followers");
                        setFollows("followers");
                      }}
                    >
                      <div className={styles.following_number}>
                        {followCount?.followers_count ?? 0}
                      </div>
                      <span>
                        {creatorType(userInfo?.role) === "Artist" || creatorType(userInfo?.role) == "Developer" &&
                        isUserProfile
                          ? "Fans"
                          : "Followers"}
                      </span>
                    </div>
                  </div>
                  <div className={styles.buttons_share_edit}>
                    {!isUserProfile && profileData.username != "loopfans" && (
                      <div
                        className={styles.edit_button}
                        onClick={() => {
                          setCollaborationRequestModal(true);
                        }}
                        onMouseEnter={() => setCollabHover(true)}
                        onMouseLeave={() => setCollabHover(false)}
                      >
                        <img
                          src={collabHover ? colabIconWhite : colabIcon}
                          alt="collab_image_loop"
                        />{" "}
                        Message
                      </div>
                    )}
                    <div
                      className={styles.edit_button}
                      onClick={() => setShare((prev) => !prev)}
                    >
                      <ReplyIcon /> Share
                    </div>
                    {isUserProfile ? (
                      <div
                        className={styles.edit_button}
                        onClick={() => setProfileModal((prev) => !prev)}
                      >
                        <span>
                          <Trans>editProfile</Trans>
                        </span>
                      </div>
                    ) : (
                      <div
                        className={styles.edit_button}
                        onClick={() => {
                          if (!userInfo) {
                            toggleLoginModal();
                            toogleRegisterModal(true);
                          } else if (!isFollowingLoading) {
                            toggleFollow();
                          }
                        }}
                      >
                        {!userInfo ? (
                          <div>Login</div>
                        ) : (
                          <div>
                            {isFollowing ? (
                              <Trans>following</Trans>
                            ) : (
                              <Trans>follow</Trans>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>

                <div className={styles.content}>
                  <div className={styles.creator}>
                    <div className={styles.details}>
                      <div>
                        <div className={styles.profile_infos}>
                          {showCTA ? (
                            <div
                              className={styles.edit_button_cta}
                              onClick={() => setProfileModal((prev) => !prev)}
                            >
                              <span> Finish setting up your profile</span>
                            </div>
                          ) : null}
                          <div className={styles.name}>
                            {details?.display_name || details?.first_name}
                            {isCreator(profileData?.role) ? (
                              <VerifiedIcon
                                fontSize="small"
                                sx={{ color: blue[400] }}
                              />
                            ) : null}
                          </div>
                          {details?.username && (
                            <div className={styles.name}>
                              <span>@{details?.username}</span>
                              {profileData?.creator_type != "None" && (
                                <div className={styles.type}>
                                  <span>
                                    {profileData?.creator_type
                                      ? `| ${profileData?.creator_type}`
                                      : ``}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                          {infos?.location && (
                            <div className={styles.location}>
                              <RoomOutlinedIcon />
                              <span>{infos?.location}</span>
                            </div>
                          )}
                        </div>
                        <div className={styles.socials}>
                          {Object.entries(socials || {}).map(
                            ([network, url], index) =>
                              url ? (
                                <a
                                  href={url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.link}
                                  key={index}
                                >
                                  <img
                                    className={styles.link_icon}
                                    src={
                                      network === "twitter"
                                        ? `/assets/icons/${network}.png`
                                        : `/assets/icons/${network}.svg`
                                    }
                                    alt={network}
                                  />
                                </a>
                              ) : null
                          )}
                        </div>
                        <VotingView
                          userId={userId}
                          setCollection={setCollection}
                          setVoting={setVoting}
                        />
                      </div>

                      {/* <div className={styles.actions}>
                        {isUserProfile ? (
                          <>
                            <Button
                          className="login_button"
                          onClick={() => {
                            logout();
                            window.location.reload();
                          }}
                        >
                          <span>Logout</span>
                        </Button>
                          </>
                        ) : !userInfo ? (
                          <Button onClick={() => toggleLoginModal()}>
                            Login
                          </Button>
                        ) : null}
                      </div> */}
                    </div>
                    {showSong === "yes" || showSong === true ? (
                      <div className={styles.player}>
                        <MediaPlayer
                          title=""
                          song={profileData?.featured_song?.id}
                          size="small"
                        />
                      </div>
                    ) : null}

                    <div className={styles.desc}>
                      <span>{details?.description}</span>
                    </div>

                    <AdvancedView
                      userInfo={userInfo}
                      data={profileData}
                      type="artist"
                    />
                  </div>
                </div>
              </div>

              <ProfileTabs
                user={profileData}
                tabName={tabName}
                handleTab={(name: string) => handleTab(name)}
              />
              {tabName === "posts" ? (
                <UnderProfileTab
                  underTabName={underTabName}
                  handleUnderTab={(name: string) => handleUnderTab(name)}
                />
              ) : null}
            </>
          )}
          {/* localhost:3000/user/marios?tab=posts&postTab=creator_posts */}
          {/* localhost:3000/user/marios?tab=posts&postTab=creator_posts */}
          <div className="content">
            {((tabName === "posts" && underTabName === "creator_posts") ||
              tabName === "") && (
              <ProfileFeed
                username={profileData?.id ?? userId}
                containerRef={containerRef}
              />
            )}
            {tabName === "posts" && underTabName === "community_posts" && (
              <ProfileFeed
                profileData={profileData}
                wall={true}
                username={profileData?.id ?? userId}
                containerRef={containerRef}
              />
            )}

            {/* About Me */}
            {tabName === "about" && (
              <AboutMe
                isUserProfile={isUserProfile}
                profileData={profileData}
                latestNfts={latestNfts}
                handleTab={(name: string) => handleTab(name)}
                userId={userId}
              />
            )}
            {/* following & followers */}
            {tabName === "following" || tabName === "followers" ? (
              <Follows
                follows={follows}
                following={followingList}
                followers={followerList}
                followListPage={followListPage}
                setFollowListPage={setFollowListPage}
                followerNftData={followerNftData}
              />
            ) : null}

            {/* Collections */}
            {isCreator(profileData?.role) && tabName === "collections" && (
              <ProfileCollections username={profileData?.username} />
            )}

            {/* Courses */}
            {isCreator(profileData?.role) && tabName === "courses" && (
              <CreatorCourses />
            )}

            {/* NFTs */}
            {tabName == "nfts" && (
              <ProfileNfts
                containerRef={containerRef}
                address={profileData?.wallet_address}
              />
            )}

            {/* Gallery */}
            {isCreator(profileData?.role) && tabName === "gallery" && (
              <ProfileGallery isFollowing={isFollowing} creator={profileData} />
            )}

            {/* Events */}
            {isCreator(profileData?.role) && tabName === "events" && (
              <ProfileEvents creator={profileData} />
            )}
          </div>
        </>
      ) : (
        <div className="loader" />
      )}
      {profileModal ? (
        <ModalProfile
          setProfileModal={setProfileModal}
          profileData={profileData}
          infos={infos}
          max={max}
          handleChange={handleChange}
          updateAbout={updateAbout}
          details={details}
          pRef={pRef}
          setImageProfile={setImageProfile}
          setImageCover={setImageCover}
          handleSocialChange={handleSocialChange}
          socials={socials}
          songFile={songFile}
          setSongFile={setSongFile}
          setShowSong={setShowSong}
          showSong={showSong}
        />
      ) : null}
      {share ? (
        <ShareComponent setShare={setShare} profile={profileData} />
      ) : null}

      {!isUserProfile && profileData?.username != "loopfans" && (
        <div className={styles.type}>
          <CollaborationRequest
            creatorId={profileData?.id}
            showModal={collaborationRequestModal}
            setShowModal={setCollaborationRequestModal}
          />
        </div>
      )}

      {voting ? (
        <VoteModal collection={[{ info: collection }]} setVoting={setVoting} />
      ) : null}
    </div>
  );
}
