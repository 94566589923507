import React, { useEffect, useRef, useState } from "react";
import styles from "./discover.module.scss";
import { DirectusAudio, DirectusImage } from "app/core/common";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { Link } from "react-router-dom";
import { Creator } from "app/api/common.api";
import { useMediaPlayer } from "app/context/mediaPlayerContext";

export default function ArtistSong({
  creator,
  playingSong,
  setPlayingSong,
}: {
  creator: Creator;
  playingSong?: string;
  setPlayingSong?: (playingSong?: string) => void;
}) {
  const { tooglePlayPause, play } = useMediaPlayer();
  const [playing, setPlaying] = useState<boolean>(false);
  const [currentSong, setCurrentSong] = useState(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const song = creator?.song?.id;

  const handlePlayer = () => {
    tooglePlayPause(false);
    setPlayingSong(song);
    setCurrentSong(song);
    if (playingSong === currentSong) {
      setPlaying((prev) => !prev);
      playing ? audioRef.current?.pause() : audioRef.current?.play();
    } else {
      setPlaying(false);
      audioRef.current?.pause();
      // Send Song Play Event
    }
  };

  useEffect(() => {
    if (play) {
      setPlaying(false);
      audioRef.current?.pause();
    }
  }, [play]);

  useEffect(() => {
    if (playingSong === song) {
      setPlaying(true);
      audioRef.current?.play();
    } else {
      setPlaying(false);
      audioRef.current?.pause();
    }
  }, [playingSong, song]);

  useEffect(() => {
    // Logic to stop playing when component unmounts
    return () => {
      setPlaying(false);
    };
  }, []);

  return (
    <div className={styles.song_container}>
      <Link
        to={`/user/${creator?.artist?.id}`}
        className={styles.artist_picture}
        style={{
          backgroundImage: `url(${DirectusImage(creator?.artist?.avatar?.id)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <span className={styles.artist_name}>
          {creator?.artist?.display_name ?? creator?.artist?.first_name}
        </span>
      </Link>
      <div className={styles.song_info}>
        <span className={styles.song_genre}>
          {creator?.leaderboard?.genre?.name}
        </span>
        <span className={styles.song_division}>
          {creator?.leaderboard?.division?.name}
        </span>
        <div className={styles.club_details_bottom}>
          <div className={styles.club_details_bottom_song}>
            <span>
              {"WMA song uploaded by"}(
              {creator?.artist?.display_name ?? creator?.artist?.first_name})
            </span>
          </div>
        </div>
      </div>
      <audio ref={audioRef} src={DirectusAudio(song)}></audio>
      <div className={styles.play_icon}>
        {song && (
          <div
            className={styles.club_details_bottom_icons}
            onClick={() => handlePlayer()}
          >
            {!playing ? (
              <PlayCircleOutlineIcon fontSize="large" />
            ) : (
              <PauseCircleOutlineIcon fontSize="large" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
