export const IPFS_GATEWAY = `https://nft-api.loop.fans/`;
export const INDEXER_ENDPOINT = `${process.env.REACT_APP_MIDDLEWARE}/indexer`;
export const MINTER_ENDPOINT = `${process.env.REACT_APP_MIDDLEWARE}`;


// Safe URL
export const safeURL = (url: string) => {
  return url.split("?")[0];
}

export const creatorType = (role: string) => {
  const types = {
    "cd70c6cd-0266-4b9c-a42e-eaf0a482f417": "Artist",
    "ab50c41f-02ea-4ddb-a4ce-2f6b05a14239": "Staff",
    "546a5a4e-812c-4eab-a62f-2f963b1e2878": "Developer",
    "21052289-c845-44bf-8be0-2bc9ea7cbc1f": "Fan",
  };
  return types[role] || "Fan";
};

export const isAdmin = (role: string) => {
  const list = [
    "ab50c41f-02ea-4ddb-a4ce-2f6b05a14239",
    "546a5a4e-812c-4eab-a62f-2f963b1e2878",
  ];
  return list.includes(role) ? true : false;
};

export const isCreator = (id: string) => {
  const creator = [
    "cd70c6cd-0266-4b9c-a42e-eaf0a482f417",
    "546a5a4e-812c-4eab-a62f-2f963b1e2878",
  ];
  const dev = "6e5d4c7b-1f46-41d4-9f03-3f2d7e51a58c";
  return creator.includes(id) || id === dev ? true : false;
};

export const IPFSImage = (url: string, image: boolean) => {
  if (!url.startsWith("ipfs")) {
    return DirectusImage(url);
  }

  const isFormat = url.match(/\.(jpeg|jpg|gif|png|webp)$/);
  const ipfsUrl = IPFS_GATEWAY + url.replace(":/", "");
  return image && isFormat
    ? `${ipfsUrl}?img-width=700&img-quality=50&img-fit=contain&img-format=webp`
    : ipfsUrl;
};

export const DirectusImage = (id: string, quality?: number) => {
  return `https://loop-markets.directus.app/assets/${
    id === undefined ? DefaultAvatar : id
  }?fit=contain&webp=1`;
};
export const DirectusAudio = (id: string) => {
  return `https://loop-markets.directus.app/assets/${id}`;
};
export const DirectusAssets = (id: string) => {
  return `https://loop-markets.directus.app/assets/${id}`;
};

export const DefaultAvatar = "d7993673-6704-42f4-a174-20f5b3d58417";

export const AWSLink = (id: string) => {
  return `https://loop-arena-cdn.fra1.cdn.digitaloceanspaces.com/Arena/${id}`;
};

export const CourseUrl = (title: string) =>
  title?.toLowerCase().replace(/\s/g, "-");

export const getParam = (name: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.get(name);
  return param ? param : null;
};

export type ProfileListType = {
  id: string;
  role: string;
  first_name: string;
  display_name: string;
  username: string;
  description: string;
  avatar: { id: string };
  walletAddress: string;
  user: any;
};

export type FollowCountType = {
  following_count: number;
  followers_count: number;
};

export type CurrencyRateType = {
  code: string;
  value: number;
  base_currency: string;
};

export interface NFT {
  id: number;
  collection_id: number;
  token_id: string;
  name: string;
  description: string;
  image: string;
  token_uri: string;
  tier: number;
  owner: string;
  created_at: string;
  collection: {
    id: number;
    name: string;
    symbol: string;
    description: string;
    contract: string;
    created_at: string;
  };
  attributes: [
    {
      id: number;
      collection_id: number;
      token_id: string;
      attribute_key: string;
      attribute_value: string;
    }
  ];
}

export interface FeedObject {
  id: string;
  type: string;
  date_created: string;
  visibility: string;
  access: boolean;
  title?: string;
  content: string;
  image: {
    id: string;
  };
  likes: number;
  liked: boolean;
  comments: number;
  artist: {
    id: string;
    username: string;
    first_name: string;
    display_name: string;
    avatar: string;
    role?: string;
  };
  wall_user: {
    first_name: string;
    display_name: string;
    username: string;
    avatar: string;
    id: string;
    role?: string;
  };
}

export interface DetailViewProps {
  show: boolean;
  toggleModal: any;
  index: any;
  setIndex: any;
  items: any;
  setItems?: any;
  currentTime?: number;
  moreExists?: boolean;
  fetchMoreFeed?: any;
  feedLoading?: boolean;
}

export interface UserData {
  id: string;
  wallet_address: string;
  profile_id: string;
  access_courses: boolean;
  role: string;
  username: string;
  display_name: string;
  first_name: string;
  description: string;
  about: string;
  avatar: string;
  onboard: boolean;
  background: {
    id: string;
  };
  verified: boolean;
  socials: [
    {
      network: string;
      url: string;
    }
  ];
}

export interface ProfileData {
  id: string;
  access_courses: boolean;
  role: string;
  username: string;
  display_name: string;
  first_name: string;
  last_name: string;
  description: string;
  about: string;
  wallet_address: string;
  show_featured_song: boolean;
  avatar: {
    id: string;
  };
  background: {
    id: string;
  };
  verified: boolean;
  socials: {
    spotify: "";
    soundcloud: "";
    apple: "";
    instagram: "";
    youtube: "";
    tiktok: "";
    facebook: "";
    twitter: "";
    custom_link: "";
  };
  creator_type: string;
  featured_song: {
    id: string;
  };
  location: string;
  featured: boolean;
}

/***********************/
/*  COLLECTION TYPES   */
/***********************/
export interface CreatorCollections {
  id: string;
  project_name: string;
  project_slug: string;
  project_status: string;
  status: string;
  banner: {
    id: string;
  };
  collection_type: string;
  launchpad_type: {
    collections_type: {
      name: string;
      desc: string;
    };
    fan_collection: {
      name: string;
      description: string;
      url: string;
      faqs: { Questions: string; Answers: string };
    };
    benefits: {
      benefit: any;
    };
  };
}

export interface CreatorCourses {
  id: number;
  slug: string;
  title: string;
  description: string;
  first_name: string;
  banner: { id: string };
  image: { id: string };
  creator: {
    id: string;
    first_name: string;
  };
}

export interface CourseItems {
  id: number;
  title: string;
  description: string;
  first_name: string;
  banner: { id: string };
  image: { id: string };
  creator: {
    id: string;
    first_name: string;
  };
  introduction?: [
    {
      id: string;
      title: string;
      description: string;
      excerpt: string;
      placeholder: { id: string };
      video: { id: string };
    }
  ];
  classes?: [
    {
      id: string;
      title: string;
      description: string;
      excerpt: string;
      placeholder: { id: string };
      video: { id: string };
    }
  ];
}

export interface CourseIntro {
  id: string;
  title: string;
  description: string;
  excerpt: string;
  placeholder: { id: string };
  video: { id: string };
  course_id: {
    id: string;
    title: string;
    creator: {
      id: string;
      first_name: string;
    };
  };
}

export interface CourseClass {
  id: string;
  title: string;
  description: string;
  excerpt: string;
  placeholder: { id: string };
  video: { id: string };
  course_id: {
    id: string;
    title: string;
    creator: {
      id: string;
      first_name: string;
    };
  };
}

export type LaunchPadInfo = {
  MINTER?: string;
  logo: string;
  icon: string;
  name: string;
  description?: string;
  status: "live" | "upcoming" | "completed";
  website: string;
  twitter: string;
  discord: string;
  mintPrice?: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  cirSupply?: number;
  maxSupply?: number;
};

export type ProjectInfo = {
  primary_usecase: string;
  additional_usecase: string;
  pre_mint: number;
  max_supply: number;
  founder_status: string;
  community_wallet: string;
};

export type LaunchPadNFT = {
  [x: string]: any;
  CREATOR: string;
  MINTER: string;
  NFT: string;
  url?: string;
  launchInfo: LaunchPadInfo;
  projectInfo?: ProjectInfo;
  has_policy?: true;
  has_tos?: true;
  policy_link?: string;
  tos_link?: string;
  disabled: boolean;
};

export type MintConfigCosmWasm = {
  nft_addr: string;
  nft_max_supply: number;
  nft_current_supply: number;
  nft_price_amount: string;
  nft_symbol: string;
  mint_start_time: number;
  public_mint_limit: number;
  public_mint_period: number;
  whitelist_mint_limit: number;
  whitelist_mint_period: number;
  public_mint_price?: string;
  whitelist_mint1_limit?: number;
  whitelist_mint1_period?: number;
  whitelist_mint1_price?: string;
  whitelist_mint2_limit?: number;
  whitelist_mint2_period?: number;
  whitelist_mint2_price?: string;
};

export type TierInfo = {
  creator: string;
  current_supply: string;
  description: string;
  max_supply: string;
  name: string;
  tier_index: string;
  token1_addr: string;
  token1_amount: string;
  token2_addr: string;
  token2_amount: string;
  vesting_period: string;
};

export type TokenReserve = {
  [token: string]: {
    tokenAmount: string;
    usdcAmount: string;
  };
};

export type NFTMinterQuery = {
  mintConfig: MintConfigCosmWasm;
  is_whitelisted: boolean;
  tier_infos: TierInfo[];
  token_reserve: TokenReserve;
  supplyByAddress: number;
};

export type TransactionType = {
  id: number;
  launchpad_id: {
    project_slug: string;
  };
  payment_status: string;
  payment_amount: string;
  payment_id: string;
  transaction_id: string;
  wallet_addr: string;
  payment_provider: string;
  reference: string;
  collection_addr: string;
  number_of_nfts: 1;
  date_updated: string;
};

export type Redeemable = {
  qr_hash: string;
  redeemed_at: null | Date;
  name: string;
  description: string;
  image_url: string;
};

/* ==================== */
/*      USER TYPES      */
/* ==================== */
export interface AlbumGallery {
  images: [
    {
      featured: boolean;
      directus_files_id: {
        id: string;
        image: string;
        title: string;
        description: string;
        created_at: string;
      };
    }
  ];
}

export interface UserAlbums {
  id: number;
  access: boolean;
  access_type: string;
  name: string;
  desc: string;
  gallery: AlbumGallery;
}
