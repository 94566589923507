import { useEffect, useRef, useState } from "react";
import SwiperCore from "swiper";
import "swiper/swiper.scss";
import CountdownComp from "./countdown";

import styles from "./index.module.scss";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import {
  CurrencyRateType,
  DirectusImage,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { set } from "numeral";
import Equalizer from "app/components/equalizer/Equalizer";
import { sendEvent } from "app/helpers/EventTracker";

const VotingSoonForm = ({
  queryResult,
  launchpadNFT,
  launchpad_imageData,
  currencyRate,
  index,
  voteModal,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT?: LaunchPadNFT;
  launchpad_imageData?: any;
  currencyRate?: CurrencyRateType;
  index?: Number;
  voteModal?: boolean;
}) => {
  const equalizerRef = useRef(null);
  const { cookie } = useHook();
  const { fan_collection } = launchpadNFT?.launchpad_type[Number(index)];
  const song = fan_collection?.song?.id;
  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo.mintPrice?.split(" ") || ["", "USDC"];

  // Handle song play
  const [isPlaying, setIsPlaying] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const [playingSong, setPlayingSong] = useState(null);

  const handlePlayer = () => {
    setPlayingSong(song);
    setCurrentSong(song);
    if (playingSong === currentSong) {
      setPlaying((prev) => !prev);
      !playing &&
        // Send Song Play Event
        sendEvent({
          cookie: cookie,
          event: "play_song",
          eventId: song,
        });
    } else {
      setPlaying(false);
    }
  };

  useEffect(() => {
    if (playingSong === song) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [playingSong]);

  useEffect(() => {
    // Logic to stop playing when component unmounts
    return () => {
      setPlaying(false);
    };
  }, []);

  return (
    <>
      <div className={styles.project_social}>
        {/* <img src={notify} alt='thumb' /> */}
        {/* <Button
          className={styles.fav_btn}
          loading={isFavsLoading}
          disabled={!address}
          onClick={() => handleFav()}
        >
          <img src={isfav ? favA : fav} alt="thumb" />
        </Button> */}
      </div>

      <div className={styles.nft_view}>
        <div className={styles.header}>
          <div className={styles.desc}>
            <b
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)]?.fan_collection
                    ?.name,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)]?.fan_collection
                    ?.description,
              }}
            />
          </div>

          <div className={styles.artist}>
            <Link
              to={`/user/${
                launchpadNFT?.artist.username ?? launchpadNFT?.artist.id
              }`}
              className={styles.profile}
            >
              <img
                src={DirectusImage(launchpadNFT?.artist.avatar?.id)}
                alt="thumb"
              />
              <div>
                <span>
                  <>CREATOR</>
                </span>
                <p>
                  {launchpadNFT?.artist?.display_name ??
                    launchpadNFT?.artist?.first_name}
                </p>
              </div>
            </Link>
            <div className={styles.divider} />
            <div>
              <span>
                <>Collection Type</>
              </span>
              <p style={{ textTransform: "capitalize" }}>
                <>{launchpadNFT?.collection_type}</>
              </p>
            </div>
          </div>

          {/* Media Player */}
          {song && (
            <div className={styles.mediaPlayer}>
              <div>
                <p className={styles.price_text}>Track</p>
              </div>
              <button
                className={playing ? styles.playing : styles.playSong}
                onClick={() => handlePlayer()}
              >
                {playing ? (
                  <span>
                    <img src="/assets/icons/media_pause.svg" />
                  </span>
                ) : (
                  <span>
                    <img src="/assets/icons/media_play.svg" />
                  </span>
                )}
              </button>
              {!playing ? null : (
                <Equalizer
                  playing={playing}
                  equalizerRef={equalizerRef}
                  setPlaying={setIsPlaying}
                  song={song}
                  classes={styles.equalizer}
                  barLimit={24}
                />
              )}
            </div>
          )}

          {/* NFT Mint Section */}
          <div className={styles.mint_live}>
            <div>
              <p className={styles.price_text}>Vote</p>
            </div>

            <button className={styles.mint_countdown}>
              <span className={styles.soon}>
                <>Starting Soon</>
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VotingSoonForm;
